

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
  --hover-color:  #f0f0f0;
  --todaysDate-color: #000000
}

/* GENERAL */



body {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-color);
  background: var(--bg-color);
  position: relative;
}


.prevWeek {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */
  flex: 1; /* Fill up remaining space */
  width: 55px;
  height: 35.6px;
}

.nextWeek {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */
  flex: 1; /* Fill up remain∂daying space */
  width: 40px;
  height: 35.6px;
}

.prevWeek:hover,
.nextWeek:hover {
  background-color: var(--hover-color);
  cursor:pointer;
}

.days .row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.days .row .dayNames,
.days .row .dayNumbers,
.days .row .dayContent {
  flex-direction: row;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}


.col-center {
  justify-content: center;
  text-align: center;
}

/* Calendar */

.calendar {
  display: inline-block;
  position: relative;
  width: 96vw;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 0.5em 0;
  border-bottom: 1px solid var(--border-color);
}

/* Make todays day name and number black and bold */
.calendar .dayNames .today,
.calendar .dayNumbers .today {
  color: var(--todaysDate-color);
  font-weight: 700;
}


